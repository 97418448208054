* {
  margin: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  scroll-padding-top: 95px;
}

body {
  font-weight: 350;
}

button {
  font-weight: inherit;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
#home {
  scroll-margin-top: 50px;
}

.no-outline:focus {
  outline: none;
}
